<template>
  <div class="lg:py-4 relative z-60 lg:px-6"
       aria-haspopup="true" :aria-controls="'menu--' +item_id" :aria-expanded="groupActive"
       tabindex="0" role="button" @keydown.enter="handleClick" @keydown.space="handleClick"
       @click="handleClick" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave"
       :class="{'py-4 px-6': stayOpen }"
  >
    <div class="flex items-center py-2 cursor-pointer"
         :class="{ 'justify-start': stayOpen, 'justify-center lg:justify-start': ! stayOpen }">
      <slot name="header"></slot>
    </div>
     <div :id="'menu--' + item_id" class=" flyout-section top-0 left-16 text-sm"
          :class="{'block': groupActive || stayOpen,
                   'hidden': groupActive === false && ! stayOpen,
                   'stay-open block my-0 static p-0': stayOpen,
                   'p-3 lg:bg-grey-100 lg:p-0 lg:static lg:block lg:mt-0 ': !stayOpen}"
          :aria-hidden="isHidden"
     >
       <slot name="links"></slot>
     </div>
  </div>
</template>

<script setup>

const  {$event, $listen, $unlisten} = useNuxtApp()

const props = defineProps({
  item_id: {
    type: String,
    required: true
  },
  stayOpen: {
    type: Boolean,
    default: false
  }
})

const groupActive = ref(false);

const isHidden = computed(() => props.stayOpen === false && groupActive.value === false && window && window.innerWidth < 840);

const handleClick = () => {
  $event('menu-clicked', props.item_id);
  groupActive.value = !groupActive.value;
}


const onMouseEnter = () => {
  if (window.innerWidth < 840) return;
  groupActive.value = true;
}

const onMouseLeave = () => {
  if (window.innerWidth < 840) return;
  groupActive.value = false;
}

$listen('menu-clicked', (id) => {
  if (id !== props.item_id) {
    groupActive.value = false;
  }
})

onBeforeUnmount(() => {
  $unlisten('menu-clicked');
});

</script>

<style scoped>
@media screen and (max-width: 1023px)
{
  .flyout-section:not(.stay-open) {
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
  }
  .flyout-section:not(.stay-open):after {
    right: 100%;
    top: 18px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #ffffff;
    border-width: 8px;
    margin-top: -8px;
  }
}
</style>